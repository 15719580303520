import React from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BarChartOutlined } from '@ant-design/icons';
import { useAuth } from '@/helpers/userContext';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  const { checkPerm } = useAuth();
  const pathName = window.location.pathname;
  const mainPathSplit = pathName.split('/');
  const [openKeys, setOpenKeys] = React.useState(mainPathSplit.length > 2 ? mainPathSplit : ['home']);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    {
      key: 'home',
      label: (
        <NavLink onClick={toggleCollapsed} to="/">
          {t('menu.home')}
        </NavLink>
      ),
      icon: (
        <NavLink className="menuItem-iocn" to="/">
          <FeatherIcon icon="home" style={{ color: 'black' }} />
        </NavLink>
      ),
    },
    {
      key: 'administrating',
      label: t('menu.administrating'),
      icon: (
        <NavLink className="menuItem-iocn" to="/administrating/users">
          <FeatherIcon icon="star" style={{ color: 'black' }} />
        </NavLink>
      ),
      children: [
        {
          key: 'users',
          label: (
            <NavLink onClick={toggleCollapsed} to="/administrating/users">
              {t('user.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/administrating/users">
              <FeatherIcon icon="user" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'administrating.users.view',
        },
        {
          key: 'roles',
          label: (
            <NavLink onClick={toggleCollapsed} to="/administrating/roles">
              {t('role.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/administrating/roles">
              <FeatherIcon icon="flag" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'administrating.roles.view',
        },
      ],
    },
    {
      key: 'crm',
      label: t('menu.crm'),
      icon: (
        <NavLink onClick={toggleCollapsed}>
          <FeatherIcon icon="users" style={{ color: 'black' }} />
        </NavLink>
      ),
      children: [
        {
          key: 'orders',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/orders">
              {t('orders.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/orders">
              <FeatherIcon icon="file-text" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'crm.orders.view',
        },
        {
          key: 'site-applications',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/site-applications">
              {t('site_applications.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/site-applications">
              <FeatherIcon icon="inbox" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'crm.site_applications.view',
        },
        {
          key: 'binotel-auto-reply-settings',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/binotel-auto-reply-settings">
              {t('binotel_auto_reply_settings.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/binotel-auto-reply-settings">
              <FeatherIcon icon="shuffle" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'crm.binotel_auto_reply_settings.view',
        },
        {
          key: 'promo-codes',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/promo-codes">
              {t('promo_codes.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/promo-codes">
              <FeatherIcon icon="gift" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'crm.promo_codes.view',
        },
        {
          key: 'short-links',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/short-links">
              {t('short_links.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/promo-codes">
              <FeatherIcon icon="link" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'crm.short_links.view',
        },
      ],
    },
    {
      key: 'cms',
      label: t('menu.cms'),
      icon: (
        <NavLink onClick={toggleCollapsed}>
          <FeatherIcon icon="image" style={{ color: 'black' }} />
        </NavLink>
      ),
      children: [
        {
          key: 'categories',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/categories">
              {t('categories.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/categories">
              <FeatherIcon icon="git-branch" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'cms.categories.view',
        },
        {
          key: 'head-categories',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/head-categories">
              {t('head_categories.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/head-categories">
              <FeatherIcon icon="settings" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'cms.header_categories.view',
        },
        {
          key: 'recommended-lists',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/recommended-lists">
              {t('recommended_products.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/recommended-lists">
              <FeatherIcon icon="thumbs-up" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'cms.recommended_lists.view',
        },
        {
          key: 'products',
          label: t('products.menu_name'),
          icon: (
            <div style={{ paddingLeft: 10 }}>
              <FeatherIcon icon="shopping-bag" style={{ color: 'black' }} />
            </div>
          ),
          children: [
            {
              key: 'product-positions',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/products/product-positions">
                  {t('products.nav_title')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/products/product-positions">
                  <FeatherIcon icon="list" style={{ color: 'black' }} />
                </NavLink>
              ),
              permission: 'cms.products.view',
            },
            {
              key: 'product-options',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/products/product-options">
                  {t('products.product_option.nav_title')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/products/product-options">
                  <FeatherIcon icon="settings" style={{ color: 'black' }} />
                </NavLink>
              ),
              permission: 'cms.products.view',
            },
            {
              key: 'product-properties',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/products/product-properties">
                  {t('products.product_properties.nav_title')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/products/product-properties">
                  <FeatherIcon icon="edit" style={{ color: 'black' }} />
                </NavLink>
              ),
              permission: 'cms.products.view',
            },
          ],
        },
        {
          key: 'cities',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/cities">
              {t('cities.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/cities">
              <FeatherIcon icon="navigation" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'cms.cities.view',
        },
        {
          key: 'stores',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/stores">
              {t('stores.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/stores">
              <FeatherIcon icon="shopping-cart" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'cms.stores.view',
        },
        {
          key: 'main-page',
          label: t('menu.main_page'),
          icon: (
            <div style={{ paddingLeft: 10 }}>
              <FeatherIcon icon="play" style={{ color: 'black' }} />
            </div>
          ),
          children: [
            {
              key: 'slider-elements',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/main-page/slider-elements">
                  {t('slider_elements.menu_name')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/main-page/slider-elements">
                  <FeatherIcon icon="sliders" style={{ color: 'black' }} />
                </NavLink>
              ),
              permission: 'cms.main_page.slider_elements.view',
            },
            {
              key: 'carousel',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/main-page/carousel">
                  {t('main_page_carousel.menu_name')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/main-page/carousel">
                  <FeatherIcon icon="image" style={{ color: 'black' }} />
                </NavLink>
              ),
              permission: 'cms.main_page.carousel.view',
            },
            {
              key: 'faqs',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/main-page/faqs">
                  {t('faqs.menu_name')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/main-page/faqs">
                  <FeatherIcon icon="help-circle" style={{ color: 'black' }} />
                </NavLink>
              ),
            },
          ],
        },
        {
          key: 'default-packing',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/default-packing">
              {t('default_packing.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/default-packing">
              <FeatherIcon icon="package" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'cms.default_packing.view',
        },
      ],
    },
    {
      key: 'warehouse',
      label: 'Склад',
      icon: (
        <NavLink onClick={toggleCollapsed}>
          <FeatherIcon icon="package" style={{ color: 'black' }} />
        </NavLink>
      ),
      children: [
        {
          key: 'tax-codes',
          label: (
            <NavLink onClick={toggleCollapsed} to="/warehouse/tax-codes">
              {t('warehouse.tax_codes.header_title')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/warehouse/tax-codes">
              <FeatherIcon icon="file" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'warehouse.tax_codes.view',
        },
        {
          key: 'oversell-limit-groups',
          label: (
            <NavLink onClick={toggleCollapsed} to="/warehouse/oversell-limit-groups">
              {t('warehouse.oversell_limit_groups.nav_title')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/warehouse/oversell-limit-groups">
              <FeatherIcon icon="database" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'warehouse.oversell_limit_groups.view',
        },
        {
          key: 'goods',
          label: (
            <NavLink onClick={toggleCollapsed} to="/warehouse/goods">
              {t('warehouse.goods.nav_title')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/warehouse/goods">
              <FeatherIcon icon="box" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'warehouse.goods.view',
        },
      ],
    },
    {
      key: 'accounting',
      label: t('menu.accounting'),
      icon: (
        <NavLink onClick={toggleCollapsed}>
          <FeatherIcon icon="dollar-sign" style={{ color: 'black' }} />
        </NavLink>
      ),
      children: [
        {
          key: 'fops',
          label: (
            <NavLink onClick={toggleCollapsed} to="/accounting/fops">
              {t('fops.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/accounting/fops">
              <FeatherIcon icon="user-check" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'accounting.fops.view',
        },
        {
          key: 'invoices',
          label: (
            <NavLink onClick={toggleCollapsed} to="/accounting/invoices">
              {t('invoices.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/accounting/invoices">
              <FeatherIcon icon="tag" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'accounting.invoices.view',
        },
        {
          key: 'payments',
          label: (
            <NavLink onClick={toggleCollapsed} to="/accounting/payments">
              {t('payments.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/accounting/payments">
              <FeatherIcon icon="tag" style={{ color: 'black' }} />
            </NavLink>
          ),
          permission: 'accounting.payments.view',
        },
      ],
    },
    {
      key: 'statistics',
      label: t('menu.statistics'),
      icon: (
        <NavLink className="menuItem-iocn" to="/statistics">
          <FeatherIcon icon="bar-chart-2" style={{ color: 'black' }} />
        </NavLink>
      ),
      children: [
        {
          key: 'revenue',
          label: (
            <NavLink onClick={toggleCollapsed} to="/statistics/revenue">
              {t('statistics.card_title.revenue')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/statistics/revenue">
              <FeatherIcon icon="trending-up" style={{ color: 'black' }} />
            </NavLink>
          ),
        },
        {
          key: 'site-statistic',
          label: (
            <NavLink onClick={toggleCollapsed} to="/statistics/site-statistic">
              {t('statistics.card_title.site_statistic')}
            </NavLink>
          ),
          icon: (
            <NavLink to="/statistics/site-statistic" className="menuItem-iocn">
              <BarChartOutlined />
            </NavLink>
          ),
        },
        {
          key: 'conversion',
          label: (
            <NavLink onClick={toggleCollapsed} to="/statistics/conversion">
              {t('statistics.card_title.conversion_and_analytics')}
            </NavLink>
          ),
          icon: (
            <NavLink to="/statistics/conversion" className="menuItem-iocn">
              <FeatherIcon icon="bar-chart" style={{ color: 'black' }} />
            </NavLink>
          ),
        },
      ],
    },
  ];

  const checkItem = (item) => {
    if (item.children) {
      // eslint-disable-next-line no-return-assign
      return (item.children = item.children.filter(checkItem)).length;
    }

    return !item.permission || checkPerm(item.permission);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode="inline"
      defaultSelectedKeys={mainPathSplit.length === 1 ? ['home'] : mainPathSplit}
      defaultOpenKeys={mainPathSplit.length > 2 ? mainPathSplit : ['home']}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      items={items.filter(checkItem)}
      style={{ backgroundColor: '#e2e2e2' }}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
