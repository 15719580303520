import styled from 'styled-components';
import { Button, Card, Form, List, Pagination, Progress, Row } from 'antd';

const ProductImageUploadStyled = styled.div`
  margin-top: 10px !important;
  box-sizing: border-box;
  padding: 0;
  color: #272b41;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  line-height: 1.5715;

  .ant-upload {
    border-spacing: 6px;
    border-width: 2px;
    border-radius: 10px;
    background: ${({ theme }) => theme['bg-color-light']};
    border-color: ${({ theme }) => theme['border-color-deep']};
    padding: 50px;
    @media only screen and (max-width: 575px) {
      padding: 15px !important;
    }

    .ant-upload-drag-icon {
      i,
      svg {
        color: ${({ theme }) => theme['extra-light-color']};
      }
    }

    .ant-upload-text {
      font-weight: 500;
      margin-bottom: 8px;
    }

    .ant-upload-hint {
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme['gray-color']};

      span {
        color: ${({ theme }) => theme['secondary-color']};
      }
    }
  }

  .list-container {
    transition: opacity 0.3s, height 0.3s;

    .list-item {
      height: 100%;
      padding: 0;
      border: 0 none;
      margin-top: 25px;
      border-radius: 4px;
      position: relative;
      font-size: 14px;

      .list-item-info {
        height: 100%;
        padding: 0;
        transition: background-color 0.3s;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .list-item-thumbnail {
          position: relative;
          top: 0;
          min-width: 100px;
          width: auto;
          height: 100%;
          line-height: 60px;
          text-align: center;
          opacity: 0.8;

          .primary-image {
            border: 3px solid #a3dea3;
          }

          img {
            max-width: 100px;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            display: block;
            overflow: hidden;
          }
        }

        .list-item-name {
          font-weight: 500;
          color: rgb(39, 43, 65);
          flex: auto;
          display: inline-block;
          box-sizing: border-box;
          max-width: 100%;
          padding: 0 8px 0 48px;
          overflow: hidden;
          line-height: 44px;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: all 0.3s;
          width: 100%;
        }

        .list-item-actions {
          position: relative;
          top: 0;
          flex: 0;
          right: 0;
          display: flex;
          gap: 20px;

          .ant-btn-sm {
            height: 22px;
            line-height: 1;
            vertical-align: top;

            .delete-btn {
              width: 15px;
              color: rgb(255, 77, 79);
            }

            .make-primary-btn {
              width: 15px;
              color: rgb(132, 205, 132);
            }
          }
        }
      }
    }
  }
`;

const ProductSizeFromStyled = styled.div`
  .container {
    margin: 20px 0 20px 0;

    .heading {
      display: flex;
      justify-content: space-between;
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }

  .ant-empty {
    margin-bottom: 20px;
  }
`;

const ProductSizeStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;

  .ant-form-item {
    width: 100%;

    .ant-form-item-control-input-content {
      display: flex;
    }

    .ant-btn {
      height: 48px;
      padding: 0 10px;
    }
  }

  .fa {
    cursor: pointer;
    font-size: 22px;
  }
`;

const ProductItemStyled = styled.div`
  margin-bottom: 20px;
  position: relative;

  .error {
    margin-left: 20px;
    display: flex;
    gap: 5px;
    color: red;
    align-items: center;
  }
`;

const CardOptionsStyled = styled(Card)`
  &&&& .ant-card-body {
    padding: 15px 40px !important;
  }

  .ant-card-actions {
    border-radius: 0 0 20px 20px;
    padding: 0 40px;

    & > li {
      text-align: left;
      width: fit-content !important;
    }
  }

  .add-btn {
    padding: 0;
    transition: color 0.3s ease;

    &:hover {
      color: #2c99ff;
    }

    &:after {
      display: none;
    }

    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      background: transparent;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.25);
      pointer-events: none;
    }
  }

  .not-found-options {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 16px;
      font-weight: 500;
      padding: 20px;
      background: #f3f4f6;
      border-radius: 10px;
    }
  }
`;

const CardOptionsItemStyled = styled(List.Item)`
  padding: ${(props) => (props.edit ? '10px 25px' : '10px 0')};
  box-shadow: ${(props) => (props.edit ? '0 0 10px 0 #4545cc' : 'none')};
  position: relative;
  transition: all 0.3s ease;

  .ant-list-item-action-split {
    display: none;
  }

  .types-options {
    position: absolute;
    top: 50%;
    right: 13%;
    font-size: 12px;
    font-weight: 700;
    background: #5f63f2;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
    transform: translateY(-50%);
  }

  .ant-list-item-action {
    .ant-btn {
      height: auto;
      padding: 5px;
    }
  }

  .add-btn {
    padding: 0 9.5px;
    height: 22px;
    font-size: 11px;
    transition: color 0.3s ease;

    &:hover {
      color: #2c99ff;
    }

    &:after {
      display: none;
    }
  }
`;

const OptionFormItemStyled = styled(Form.Item)`
  position: relative;

  .ant-form-item-explain + div {
    display: none !important;
  }

  .ant-form-item-explain {
    position: absolute;
    top: calc(50% + 8px);
    font-size: 10px;
    left: 0;
    color: red;
    margin: 0;
  }

  .ant-form-item-margin-offset {
    margin: 0 !important;
  }

  .ant-form-item-row {
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-bottom: 14px;
  }

  .ant-form-item-control-input {
    min-height: 0;
  }

  .ant-form-item-control {
    width: auto !important;
  }

  .ant-form-item-label {
    padding: 0;
    line-height: 1;
  }

  .ant-select-selector {
    min-width: 150px;
  }

  &.ant-form-item.ant-form-item-has-success .ant-input {
    border-color: #e3e6ef !important;
  }

  &.ant-form-item-has-success .ant-input {
    border-color: #e3e6ef;
  }
`;

const OptionTagParamStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;

  .site-tag-plus {
    background: #fff;
    border-style: dashed;
  }

  .edit-tag {
    user-select: none;
    display: flex !important;

    .tag-value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 133.6px;
      display: inline-block;
    }
  }

  .tag-input {
    width: 133.6px;
    margin-right: 8px;
    vertical-align: top;
    max-height: 22px;
    font-size: 11px;
  }
`;

const CardVariantsStyled = styled(Card)`
  border: ${(props) => (props.error ? '1px solid red' : '1px solid #f0f0f0')};

  .ant-card-head {
    border-bottom: none;
  }

  &&&& .ant-card-body {
    padding: 5px 0 20px 0 !important;
  }

  th.ant-table-cell {
    text-align: center;
  }

  td.ant-table-cell {
    text-align: center;
  }

  .ant-card-actions {
    border-radius: 0 0 20px 20px;
    padding: 0 40px;

    & > li {
      text-align: left;
    }
  }

  .ant-card-extra {
    gap: 15px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-table table {
    text-align: left;
  }

  .ant-table-cell {
    background: #ffffff;
  }
`;

const DrawerProductListStyled = styled(List.Item)`
  margin-bottom: 10px;
  border: 1px solid #e3e6ef;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: revert;

  &:focus {
    background: red;
  }

  .ant-list-item-action {
    margin-left: auto;

    & > li {
      padding: 0;
    }
  }
`;

const PreviewImageVariantStyled = styled.div`
  display: flex;
  justify-content: center;

  .add {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #e3e6ef;
    border-radius: 9px;
    transition: color 0.3s, border-color 0.3s;
    color: #e3e6ef;
    background: transparent;

    &:hover {
      color: #8c94ff;
      border-color: #8c94ff;
    }

    .anticon-plus {
      font-size: 30px;
      color: inherit;
    }

    .anticon svg {
      color: inherit;
    }
  }

  .view {
    border: 2px dashed #e3e6ef;
    border-radius: 9px;
    transition: color 0.3s, border-color 0.3s;
    width: fit-content;
    background: transparent;
    padding: 0;

    &:hover {
      border-color: #8c94ff;
    }

    img {
      object-fit: contain;
      width: 50px;
      height: 50px;
      border-radius: 9px;
    }
  }
`;

const VariantImagesRowStyled = styled(Row)`
  padding: 20px 10px 10px 10px !important;
  border-radius: 5px;
  border: 1px solid #e3e6ef;
  position: relative;
  height: 100%;

  .title {
    position: absolute;
    top: -12px;
    left: 5px;
    font-size: 12px;
    background: #fafafa;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #e3e6ef;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 0;
  }

  .upload-qty {
    position: absolute;
    top: -12px;
    right: 5px;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: #5f63f2;
    color: #ffffff;
  }

  .container-items {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .empty-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const VariantCardFileStyled = styled.div`
  border: 1px solid #e3e6ef;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: ${(props) => (props.active ? 'box-shadow 0.3s ease' : 'box-shadow 0.6s ease')};
  box-shadow: ${(props) => (props.active ? '0 0 3px 0 #5f63f2' : 'none')};

  img {
    object-fit: cover;
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 9px;
  }

  .card-file-actions {
    display: flex;
    gap: 5px;

    .ant-btn {
      border: none;

      .anticon {
        font-size: 20px;
      }
    }

    .main {
      transition: color 0.4s ease-in-out;
      color: ${(props) => (props.primary ? '#8c94ff' : '#e3e6ef')};

      &:hover {
        color: #8c94ff;
      }
    }

    .view {
      transition: color 0.4s ease-in-out;
      color: ${(props) => (props.active ? '#8c94ff' : '#e3e6ef')};

      &:hover {
        color: #8c94ff;
      }
    }

    .delete {
      color: rgba(255, 99, 71, 0.5);
      transition: color 0.3s ease;

      &:not(:disabled):hover {
        color: tomato;
      }
    }
  }
`;

const ViewImageColStyled = styled.div`
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  height: 412px;

  .title {
    position: absolute;
    top: -12px;
    right: 5px;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: #fafafa;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #e3e6ef;
  }

  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .not-view-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 15px;
  }
`;

const CardProductImagesStyled = styled(Card)`
  border-radius: 20px;
  border: none;
  height: 100%;

  .ant-card-head {
    border-radius: 20px 20px 0 0;
    padding: 0 40px !important;
  }

  .ant-card-head-title {
    padding: 26px 0 25px;
  }

  .ant-card-actions {
    border-radius: 0 0 20px 20px;

    & > li {
      margin: 12px 25px;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const ProductImagesPreviewStyled = styled.div`
  border: 1px solid rgb(227, 230, 239);
  padding: 15px 10px 10px 10px;
  border-radius: 10px;
  position: relative;
  height: 200px;

  .preview-title {
    position: absolute;
    top: -14px;
    right: 12px;
    font-size: 12px;
    background: #5f63f2;
    color: #ffffff;
    border: 1px solid #e3e6ef;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 0;
  }

  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .not-view-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 15px;

    span {
      text-align: center;
    }
  }
`;

const ProductImagesRowStyled = styled(Row)`
  padding: 20px 10px 10px 10px !important;
  border-radius: 10px;
  border: 1px solid #e3e6ef;
  position: relative;
  height: 335px;
  margin: 0 !important;

  .upload-title {
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 12px;
    background: #fafafa;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #e3e6ef;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 0;
  }

  .upload-qty {
    position: absolute;
    top: -12px;
    right: 12px;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: #5f63f2;
    color: #ffffff;
  }

  .container-items {
    width: 100%;
    display: flex;
    height: fit-content;
  }

  .empty-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ProductCardFileStyled = styled.div`
  border: 1px solid #e3e6ef;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  transition: ${(props) => (props.active ? 'box-shadow 0.3s ease' : 'box-shadow 0.6s ease')};
  box-shadow: ${(props) => (props.active ? '0 0 3px 0 #5f63f2' : 'none')};
  height: 136.78px;

  .ant-progress-text {
    width: auto;
  }

  img {
    object-fit: cover;
    min-width: 50px;
    width: 100%;
    height: 70px;
    border-radius: 9px;
  }

  .card-file-actions {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .ant-btn {
      border: none;
      height: fit-content;
      width: fit-content;

      .anticon {
        font-size: 20px;
      }
    }

    .main {
      transition: color 0.4s ease-in-out;
      color: ${(props) => (props.primary ? '#8c94ff' : '#e3e6ef')};

      &:hover {
        color: #8c94ff;
      }
    }

    .view {
      transition: color 0.4s ease-in-out;
      color: ${(props) => (props.active ? '#8c94ff' : '#e3e6ef')};

      &:hover {
        color: #8c94ff;
      }
    }

    .delete {
      color: rgba(255, 99, 71, 0.5);
      transition: color 0.3s ease;

      &:not(:disabled):hover {
        color: tomato;
      }
    }
  }
`;

const ApproveButtonStyled = styled(Button)`
  position: absolute !important;
  top: -12px;
  right: 25px;
  background: ${(props) => (props.approve ? '#CBCBCB' : 'rgb(32, 201, 151)')};
  color: #ffffff;
  border: none;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.approve ? 'default' : 'pointer')};

  &:hover {
    background: ${(props) => (props.approve ? 'rgb(32, 201, 151)' : '#CBCBCB')};
    color: ${(props) => (props.approve ? 'rgb(32, 201, 151)' : '#ffffff')};
  }

  &:disabled {
    background: #cbcbcb;
    cursor: default;
    border-color: transparent;
    color: #ffffff;

    &:hover {
      background: #cbcbcb;
      border-color: transparent;
      color: #ffffff;
    }

    .icon {
      font-size: 18px;
    }
  }
`;

const CommentsTableStyled = styled(Card)`
  position: relative;
  height: 100%;

  .rating-and-create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;

    .created-at {
      background-color: #5f63f2;
      color: #ffffff;
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
    }
  }

  .personal-info-wrapper {
    border: 1px solid #e3e6ef;
    border-radius: 10px;
    padding: 10px;
    position: relative;

    .title {
      position: absolute;
      top: -10px;
      right: 7px;
      background-color: #5f63f2;
      color: #ffffff;
      padding: 0 5px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 600;
    }

    .personal-info-container {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 5px;

      > div {
        flex: 1 1 33.3%;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 1;

        h6 {
          margin-bottom: 5px;
          background-color: #e3e6ef;
          padding: 2px 5px;
          border-radius: 5px;
          font-size: inherit;
          width: fit-content;
        }
      }
    }
  }

  .personal-info-comment {
    border: 1px solid #e3e6ef;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    height: 120px;

    .title {
      position: absolute;
      top: -10px;
      right: 7px;
      background-color: #5f63f2;
      color: #ffffff;
      padding: 0 5px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 600;
    }

    .comment-text {
      max-height: 100px;
      overflow-y: auto;
    }

    p {
      line-height: 1;
      margin-bottom: 0;
    }

    .not-comment {
      color: rgb(163 163 163);
      padding: 13px 0;
      text-align: center;
      font-weight: 600;
    }
  }
`;

const StarsContainerStyled = styled.div`
  display: inline-flex;
  line-height: 1;
  gap: 5px;
`;

const StarContainerStyled = styled.span`
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
`;

const HalfStarOverlayStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  overflow: hidden;
  z-index: 1;
`;

const StarStyled = styled.svg`
  width: 20px;
  height: 20px;
  fill: ${({ filled }) => (filled ? '#fc0' : '#D0D0D0')};
`;

const PaginationStyled = styled(Pagination)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 !important;

  .ant-pagination-next,
  .ant-pagination-prev {
    position: relative;

    a {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .total {
    border: 1px solid #f1f2f6 !important;
    background-color: #fff;
    padding: 6.5px;
    border-radius: 4px;
    font-size: 13px;
    color: #5a5f7d;
  }
`;

const ReviewsTabStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  .qty {
    background: white;
    border-radius: 10px;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #e3e6ef;
  }
`;

const PropertiesInfoMessage = styled.div`
  background: #5f63f2;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  margin-bottom: 20px;
  font-weight: 600;
`;

const PropertiesRowStyled = styled(Row)`
  padding: 35px 10px 10px 10px;
  border: 1px solid #e3e6ef;
  margin-bottom: 20px;
  position: relative;

  .ant-form-item {
    position: relative;
    width: 100%;
  }

  .ant-form-item-required {
    &:before {
      display: none !important;
    }
  }

  .ant-form-item-label {
    position: absolute;
    z-index: 1;
    top: -12px;
    left: 8px;
    background: white;
    padding: 0 5px;
    border-radius: 4px;
    line-height: 1;

    & > label {
      font-size: 10px;
      line-height: 1;
      color: gray;
    }
  }

  .ant-select-selector {
    min-width: 200px;
    width: 100%;
    min-height: 48px;
  }

  .ant-form-item-explain-error {
    font-size: 10px;
  }

  .ant-btn {
    position: absolute;
    top: -16px;
    right: -8px;

    &:hover {
      box-shadow: red 0 0 5px 0;
    }
  }
`;

const PropertiesListItemStyled = styled.div`
  min-height: 48px;
  position: relative;
  border: 1px solid #e3e6ef;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  padding: 2px 10px;

  .label {
    position: absolute;
    z-index: 1;
    top: -12px;
    left: 8px;
    background: #fff;
    padding: 0 5px;
    border-radius: 4px;
    line-height: 1;
    font-size: 10px;
    color: gray;
    min-height: 14px;
  }

  .add-btn {
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 4px 6px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
    background: #fff;
    cursor: pointer;
    transition: border-color 0.3s;
      
      &:hover {
        border-color: #8c94ff;
      }
  }
    
    .clear-btn {
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;
      font-size: 11px;
      transition: color 0.3s;
      cursor: pointer;
        
        &:hover {
            color: red;
        }
    }
}

  .ant-form-item {
    margin-bottom: 0 !important;

  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-input-affix-wrapper {
    padding: 2px 4px;
    max-width: 128px;
  }

`;

const ColorPickerStyled = styled.div`
  position: relative;
  display: inline-block;
  min-width: 100%;

  .color-button {
    min-height: 48px;
    min-width: 80px;
    width: 100%;
    background: transparent;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
    padding: 5px;

    .view-color {
      border: 1px dashed black;
      border-radius: 4px;
      display: block;
      min-width: 70px;
      min-height: 25px;
    }
  }

  .popover {
    position: absolute;
    z-index: 2;
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;

    &.active {
      opacity: 1;
      transform: scale(1);
      pointer-events: auto;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }

  .picker-container {
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
  }
`;

const OptionsButtonTagStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
  min-height: 40px;
  justify-content: center;
  align-items: center;

  &:hover {
    .edit-overlay {
      opacity: 0.8;
      visibility: visible;
    }

    .edit {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .edit-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eef6f4;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1;
  }

  .edit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  }

  .option-tags-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    position: relative;
    z-index: 0;

    .ant-tag {
      padding: 0 2px;
      margin: 0;
      font-size: 12px;
    }
  }
`;

const AsyncAutoCompleteWrapperStyled = styled.div`
  position: relative;
  width: 100%;

  .suffix-icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
    display: flex;
    align-items: center;
  }

  .custom-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const ProgressBarStyled = styled(Progress)`
  .ant-progress-text {
    width: 3em;
  }
`;

const SlugContainerStyled = styled.div`
  position: relative;

  .generation-btn {
    position: absolute;
    top: 50%;
    right: 30px;
    padding: 0 2.5px 0 0;
    margin: 0;
    font-size: 18px;
    transform: translateY(-50%);
    line-height: 1;
    height: 46px;
    border: none;
    z-index: 12;

    &:after {
      display: none;
    }
  }

  .ant-input {
    padding: 12px 25px 12px 5px;
  }

  .ant-input-group-addon {
    padding: 2px;
  }
`;

export {
  ProductImageUploadStyled,
  ProductSizeFromStyled,
  ProductSizeStyled,
  ProductItemStyled,
  CardOptionsStyled,
  CardOptionsItemStyled,
  OptionFormItemStyled,
  OptionTagParamStyled,
  CardVariantsStyled,
  DrawerProductListStyled,
  PreviewImageVariantStyled,
  VariantImagesRowStyled,
  VariantCardFileStyled,
  ViewImageColStyled,
  CardProductImagesStyled,
  ProductImagesPreviewStyled,
  ProductImagesRowStyled,
  ProductCardFileStyled,
  CommentsTableStyled,
  ApproveButtonStyled,
  StarsContainerStyled,
  StarContainerStyled,
  HalfStarOverlayStyled,
  StarStyled,
  PaginationStyled,
  ReviewsTabStyled,
  PropertiesInfoMessage,
  PropertiesRowStyled,
  PropertiesListItemStyled,
  ColorPickerStyled,
  OptionsButtonTagStyled,
  AsyncAutoCompleteWrapperStyled,
  ProgressBarStyled,
  SlugContainerStyled,
};
