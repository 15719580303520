import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { Main } from '../../../styled';
import { FormWrapper } from '../../style';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Cards } from '@/components/cards/frame/cards-frame';
import FormInput from '@/form/FormInput';
import FormSubmit from '@/form/FormSubmit';
import ApiClient from '@/helpers/apiClient/ApiClient';
import FormUploadImage from '@/form/FormUploadImage';

const CategoryForm = () => {
  const { t } = useTranslation();
  const { form, id, model, handleSubmit, loading, errors } = useFormValues('categories');
  const isShowOnMainPage = model.is_show_on_main_page;
  const [availableToShowOnMainPage, setAvailableToShowOnMainPage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    ApiClient.call('get', 'categories/available-to-show-on-main-page').data((data) => {
      setAvailableToShowOnMainPage(data);
    });
  }, []);

  return (
    <>
      <PageHeader ghost title={t(id ? 'categories.edit_page_header' : 'categories.create_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col md={14} xs={24}>
              <FormWrapper>
                <Form
                  style={{ width: '100%' }}
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={{
                    is_active: true,
                    is_show_on_main_page: false,
                  }}
                >
                  <FormInput name="name" label={t('categories.fields.name')} error={errors.name} placeholder="Троянди" />
                  {id && <FormInput name="slug" disabled label={t('categories.fields.slug')} error={errors.slug} placeholder="Троянди" />}
                  {/* <FormFile accept="image/*" name="file" label={t('categories.fields.image')} /> */}

                  <Form.Item name="picture_id" label={t('categories.fields.image')} validateStatus={errors.pictures ? 'error' : false} help={errors.pictures}>
                    <FormUploadImage directory="categories" loading={loadingImage} setLoading={setLoadingImage} />
                  </Form.Item>

                  <Form.Item name="is_active" valuePropName="checked">
                    <Checkbox>{t('categories.fields.is_active')}</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_for_new_products" valuePropName="checked">
                    <Checkbox>{t('categories.fields.is_for_new_products')}</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_show_on_main_page" valuePropName="checked">
                    <Checkbox disabled={!isShowOnMainPage && !availableToShowOnMainPage}>
                      <span style={{ marginRight: 10 }}>{t('categories.fields.is_show_on_main_page')}</span>
                      {!availableToShowOnMainPage && !isShowOnMainPage && (
                        <Tooltip title={t('categories.disabled_show_on_main_page_explain')}>
                          <FontAwesome name="question-circle-o" className="super-crazy-colors" />
                        </Tooltip>
                      )}
                    </Checkbox>
                  </Form.Item>
                  <FormSubmit loading={loading} disabled={loadingImage} />
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(CategoryForm);
