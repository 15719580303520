import styled from 'styled-components';

const ZoneElemStyled = styled.li`
  display: flex;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  border: ${(props) => (props.hasError ? '2px solid red' : '1px solid lightgray')};

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .color {
    display: block;
    width: 30px;
    height: 30px;
  }

  button {
    height: 40px;
    padding: 0 11px;
    background: transparent;
    border: 0 none;
    color: ${({ theme }) => theme['extra-light-color']};
    &:hover {
      &.ant-btn-primary {
        color: ${({ theme }) => theme['primary-color']};
        background: ${({ theme }) => theme['primary-color']}10;
      }
      &.ant-btn-info {
        color: ${({ theme }) => theme['info-color']};
        background: ${({ theme }) => theme['info-color']}10;
      }
      &.ant-btn-danger {
        color: ${({ theme }) => theme['danger-color']};
        background: ${({ theme }) => theme['danger-color']}10;
      }
    }
  }
`;

const DeliveryPointStyled = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid gray;
`;

const DrawZoneLoaderStyled = styled.div`
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MapLoaderStyled = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(195, 191, 191, 0.98);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  transition: opacity 0.7s ease-in-out;
`;

const LocalityTableStyled = styled.div`
  position: relative;
  margin-top: 25px;

  .ant-table-thead {
    th {
      background: rgb(95, 99, 242);
      color: #ffffff;
    }
  }

  .add-button {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
  }

  .ant-spin-spinning {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
`;

export { ZoneElemStyled, DeliveryPointStyled, DrawZoneLoaderStyled, MapLoaderStyled, LocalityTableStyled };
