import React, { useRef, useState } from 'react';
import { Button, DatePicker, Space } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { YYYY_MM_DD } from '@/constants/momentFormat';

const DateRangeFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [state, setState] = useState({
    start: selectedKeys[0] ? dayjs(selectedKeys[0]) : null,
    end: selectedKeys[1] ? dayjs(selectedKeys[1]) : null,
    endOpen: false,
  });

  const disabledStartDate = () => {
    const { start, end } = state;
    if (!start || !end) {
      return false;
    }
    return start.valueOf() > end.valueOf();
  };

  const disabledEndDate = () => {
    const { start, end } = state;
    if (!end || !start) {
      return false;
    }
    return end.valueOf() <= start.valueOf();
  };

  const onChange = (field, value) => {
    setState((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      onChange('endOpen', true);
    }
  };

  const handleEndOpenChange = (open) => {
    onChange('endOpen', open);
  };

  const apply = () => {
    const { start, end } = state;
    if (start && end) {
      const startDate = dayjs(start).format(YYYY_MM_DD);
      const endDate = dayjs(end).format(YYYY_MM_DD);

      setSelectedKeys([startDate, endDate]);
      confirm();
    }
  };

  const reset = () => {
    setState({ start: null, end: null, endOpen: false });
    setSelectedKeys([]);
    clearFilters();
    confirm();
  };

  const setToday = () => {
    const startDate = dayjs().format(YYYY_MM_DD);
    const endDate = dayjs().format(YYYY_MM_DD);

    setSelectedKeys([startDate, endDate]);
    confirm();
  };

  return (
    <div ref={ref} style={{ padding: '10px' }}>
      <div>
        <DatePicker
          disabledDate={disabledStartDate}
          format="DD/MM/YYYY"
          value={state.start}
          placeholder="З"
          onChange={(value) => onChange('start', value)}
          onOpenChange={handleStartOpenChange}
          style={{ margin: 5 }}
        />

        <DatePicker
          disabledDate={disabledEndDate}
          format="DD/MM/YYYY"
          value={state.end}
          placeholder="По"
          onChange={(value) => onChange('end', value)}
          open={state.endOpen}
          onOpenChange={handleEndOpenChange}
          style={{ margin: 5 }}
        />
      </div>
      <Space>
        <Button type="primary" onClick={apply}>
          {t('apply')}
        </Button>
        <Button type="link" onClick={reset}>
          {t('clear')}
        </Button>
        <Button type="link" onClick={setToday}>
          Сьогодні
        </Button>
      </Space>
    </div>
  );
};

DateRangeFilter.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
};

export default DateRangeFilter;
