import { Button, Popconfirm, Table } from 'antd';
import { useMemo, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ApiClient from '@/helpers/apiClient/ApiClient';
import LocalityModal from '@/components/City/LocalityModal';
import { LocalityTableStyled } from '@/components/City/styled';

const zone = {
  city_id: '',
  ext_district_id: '',
  district_name: '',
  ext_city_id: '',
  city_name: '',
  ext_region_id: '',
  region_name: '',
};

const LocalityTable = ({ value = [], onChange, cityId }) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState(null);

  const handleUpdateLocality = (updatedData) => {
    onChange(updatedData);
  };

  const handleDeleteLocality = (locationId) => {
    ApiClient.call('delete', `delivery-points/${locationId}`).then(() => {
      const updatedValue = value.filter(({ id }) => id !== locationId);
      onChange(updatedValue);
    });
  };

  const columns = useMemo(
    () => [
      {
        title: t('cities.fields.delivery_points'),
        dataIndex: 'city_name',
        key: 'city_name',
        align: 'left',
      },
      {
        title: 'Вулиці',
        dataIndex: 'streets_loaded',
        key: 'streets_loaded',
        align: 'center',
        render: (value) => (value ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />),
      },
      {
        key: 'operation',
        align: 'end',
        render: (value, record) => {
          return (
            <>
              <Button
                size="small"
                icon={<EditOutlined />}
                type="primary"
                style={{ marginRight: 5 }}
                onClick={() => {
                  setModalData(record);
                }}
              />
              <Popconfirm title={t('cities.fields.delete_message')} onConfirm={() => handleDeleteLocality(record.id)} okText={t('cities.fields.on_ok')}>
                <Button size="small" icon={<DeleteOutlined />} danger />
              </Popconfirm>
            </>
          );
        },
      },
    ],
    [handleDeleteLocality],
  );

  return (
    <LocalityTableStyled>
      <Button
        onClick={() => {
          setModalData(zone);
        }}
        className="add-button"
      >
        {t('cities.fields.add_button')}
      </Button>
      <Table columns={columns} dataSource={value} rowKey="id" />
      {modalData && <LocalityModal value={modalData} onChange={handleUpdateLocality} cityId={cityId} localityData={value} onClose={() => setModalData(null)} />}
    </LocalityTableStyled>
  );
};

LocalityTable.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LocalityTable;
