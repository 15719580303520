import { sumBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import propTypes from 'prop-types';
import { ShortModalList } from '@/components/Order/styled';
import priceFormat from '@/helpers/priceFormat';

const ProductInfoCard = ({ title, products, itemsSum }) => {
  return (
    <Card title={title} style={{ height: '100%' }}>
      <ShortModalList>
        {products.map((item, i) => {
          const itemsSum =
            sumBy(item.items, (elem) => {
              return elem.price * elem.qty;
            }) * item.count;

          return (
            <li className="product-items" key={i}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>
                      <Link to={`/cms/products/${item.product_id}`} target="_blank">
                        {item.name}
                      </Link>
                    </td>
                    <td className="price">{priceFormat(itemsSum)}</td>
                    <td className="qty">
                      <strong style={{ textDecoration: 'underline' }}>{item.count} шт</strong>
                    </td>
                  </tr>
                  {item.items.map((good) => (
                    <tr key={good.good_id} className="good-items">
                      <td> - {good.name}</td>
                      <td className="price">{priceFormat(good.price)}</td>
                      <td className="qty">
                        <strong style={{ textDecoration: 'underline' }}>{good.qty} шт</strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </li>
          );
        })}
      </ShortModalList>
      <div className="d-flex justify-content-end mt-30">
        <strong>Сума товарів - {priceFormat(itemsSum)}</strong>
      </div>
    </Card>
  );
};

ProductInfoCard.propTypes = {
  title: propTypes.string,
  products: propTypes.array,
  itemsSum: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default ProductInfoCard;
