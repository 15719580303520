import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FeatureGroup, MapContainer, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { Col, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import { Modal } from '@/components/modals/antd-modals';
import ZoneList from '@/components/City/ZoneList';
import DeliveryZoneEditor from '@/components/City/DeliveryZoneEditor';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { DrawZoneLoaderStyled, MapLoaderStyled } from '@/components/City/styled';
import LogoSpinner from '@/widgets/LogoSpinner';

const MemoMap = memo(
  function ({ zones, setZones, onZoneChange, center, activeZone, setActiveZone }) {
    const [mapRef, setMapRef] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (mapRef && center.length) {
        mapRef.flyTo(center);
      }
    }, [center, mapRef]);

    useEffect(() => {
      if (mapRef) {
        mapRef.invalidateSize();
      }
    }, [mapRef]);

    const onCreated = (e) => {
      const { layerType, layer } = e;
      if (layerType === 'polygon') {
        const data = layer.getLatLngs()[0].map(({ lat, lng }) => {
          return [lat, lng];
        });

        const id = uuidv4();

        // eslint-disable-next-line no-underscore-dangle
        e.layer._map.removeLayer(e.layer);

        setActiveZone(id);
        setZones((value) => {
          return [...value, { coords: data, id, color: '#000000', delivery_price: '', free_delivery_from_sum: '', info_text: '' }];
        });
      }
    };

    const onEdited = (e) => {
      const {
        layers: { _layers },
      } = e;

      if (isEmpty(_layers)) {
        return;
      }

      Object.values(_layers).forEach(({ editing, options }) => {
        const coords = editing.latlngs[0][0].map(({ lat, lng }) => {
          return [lat, lng];
        });
        const { id } = options;

        onZoneChange(id, 'coords', coords);
      });
    };

    return (
      <div style={{ width: '100%' }}>
        <div style={{ height: '75vh' }}>
          <MapContainer center={center} zoom={12} scrollWheelZoom ref={setMapRef}>
            <ReactLeafletGoogleLayer
              apiKey={config.googleMapsApiKey}
              type="roadmap"
              eventHandlers={{
                load: () => setLoading(false),
              }}
            />
            <FeatureGroup>
              <MapLoaderStyled isLoading={loading}>
                <LogoSpinner />
              </MapLoaderStyled>
              <EditControl
                position="topright"
                onEdited={onEdited}
                onCreated={onCreated}
                draw={{
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  polyline: false,
                  polygon: true,
                }}
                edit={{ remove: false }}
              />
              {Boolean(zones.length) &&
                zones.map((zone) => {
                  return (
                    <Polygon
                      positions={zone.coords}
                      className={activeZone === zone.id && 'active-area'}
                      pathOptions={{
                        color: zone.color,
                      }}
                      key={uuidv4()}
                      id={zone.id}
                      eventHandlers={{
                        click: (e) => {
                          setActiveZone(e.target.options.id);
                        },
                      }}
                    />
                  );
                })}
            </FeatureGroup>
          </MapContainer>
        </div>
      </div>
    );
  },
  (oldProps, newProps) => {
    return (
      oldProps.activeZone === newProps.activeZone &&
      oldProps.zones.every((element, index) => element.color === newProps.zones[index]?.color) &&
      oldProps.zones.length === newProps.zones.length
    );
  },
);

MemoMap.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.object),
  setZones: PropTypes.func.isRequired,
  onZoneChange: PropTypes.func.isRequired,
  center: PropTypes.array,
  activeZone: PropTypes.any,
  setActiveZone: PropTypes.func,
};

const DrawZoneModal = ({ cityId, cityName, onClose }) => {
  const { t } = useTranslation();
  const [center, setCenter] = useState([]);
  const [value, setValue] = useState([]);
  const [activeZone, setActiveZone] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    async function updateCityCoords() {
      const coords = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&accept-language=ua-UA&limit=1&addressdetails=1&countrycode=UA&city=${cityName}&country=UA`,
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.length) {
            const { lat, lon } = data[0];
            return { lat, lon };
          }

          return null;
        })
        .catch(() => {
          return null;
        });

      if (coords) {
        setCenter([coords.lat, coords.lon]);
      }
    }

    updateCityCoords();
  }, [cityName]);

  useEffect(() => {
    ApiClient.call('get', `cities/${cityId}/delivery-zones`).data(setValue);
  }, [cityId]);

  const onDeleteZone = (id) => {
    setActiveZone(null);
    setValue((value) => value.filter((el) => el.id !== id));
  };

  const onZoneChange = (id, key, value) => {
    setValue((prev) => {
      return prev.map((el) => {
        if (el.id === id) {
          return { ...el, [key]: value };
        }
        return el;
      });
    });
  };

  const onSave = () => {
    setErrors({});
    ApiClient.call('post', `cities/${cityId}/update-zones`, {}, { zones: value })
      .data(() => {
        setActiveZone(null);
        message.success('Успішно');
      })
      .validation((msg, errors) => {
        message.error('Помилка валідації. Перевірте данні.');
        setErrors(errors);
      });
  };

  return (
    <Modal visible onCancel={onClose} width="90%" title={t('delivery_zones.modal_title')} onOk={onSave}>
      {center.length ? (
        <Row gutter={24}>
          <Col span={18}>
            <MemoMap zones={value} setZones={setValue} onZoneChange={onZoneChange} center={center} activeZone={activeZone} setActiveZone={setActiveZone} />
          </Col>
          <Col span={6}>
            {Boolean(activeZone) && (
              <DeliveryZoneEditor
                zone={value.find((el) => el.id === activeZone)}
                index={value.findIndex((el) => el.id === activeZone)}
                onChange={onZoneChange}
                errors={errors}
              />
            )}
            <ZoneList zones={value} onSelect={setActiveZone} onDelete={onDeleteZone} errors={errors} />
          </Col>
        </Row>
      ) : (
        <DrawZoneLoaderStyled>
          <LogoSpinner />
        </DrawZoneLoaderStyled>
      )}
    </Modal>
  );
};

DrawZoneModal.propTypes = {
  cityId: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default DrawZoneModal;
