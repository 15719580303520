import React, { useState } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { CheckOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ApproveButtonStyled,
  CommentsTableStyled,
  HalfStarOverlayStyled,
  PaginationStyled,
  StarContainerStyled,
  StarsContainerStyled,
  StarStyled,
} from '@/components/Product/style';
import ApiClient from '@/helpers/apiClient/ApiClient';

const StarRating = ({ rate }) => {
  const totalStars = 5;

  return (
    <StarsContainerStyled aria-label={`Rating of this product is ${rate} out of ${totalStars}.`}>
      {Array.from({ length: totalStars }, (_, index) => {
        const isFilled = index < Math.floor(rate);
        const isHalfFilled = index === Math.floor(rate) && rate % 1 !== 0;
        const halfStarWidth = (rate % 1) * 100;

        return (
          <StarContainerStyled key={index}>
            <StarStyled xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" filled={isFilled}>
              <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
            </StarStyled>
            {isHalfFilled && (
              <HalfStarOverlayStyled style={{ width: `${halfStarWidth}%` }}>
                <StarStyled xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" filled>
                  <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                </StarStyled>
              </HalfStarOverlayStyled>
            )}
          </StarContainerStyled>
        );
      })}
    </StarsContainerStyled>
  );
};

StarRating.propTypes = {
  rate: PropTypes.number,
};

const ApproveButton = ({ review, setReviewsData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onApproveComment = (value) => {
    const { id, moderated } = value;

    if (moderated) {
      return;
    }

    setLoading(true);
    ApiClient.call('post', `/products/reviews/${id}/submit`, {}, value)
      .data(() => {
        setReviewsData((prev) => prev.map((review) => (review.id === id ? { ...review, moderated: true } : review)));
      })
      .finally(() => setLoading(false));
  };

  return (
    <ApproveButtonStyled
      approve={Number(review.moderated)}
      loading={loading}
      icon={review.moderated ? <CheckOutlined className="icon" /> : null}
      onClick={() => onApproveComment(review)}
      disabled={review.moderated}
    >
      {review.moderated ? t('products.reviews_page.confirmed') : t('products.reviews_page.confirm')}
    </ApproveButtonStyled>
  );
};

ApproveButton.propTypes = {
  review: PropTypes.object,
  setReviewsData: PropTypes.func,
};

const ReviewsPage = ({ reviewsData, setReviewsData, total, page, perPage, setPerPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePageChange = (newPage, newPageSize) => {
    setPerPage(newPageSize);
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        {reviewsData.map((review) => (
          <Col key={review.id} span={12}>
            <CommentsTableStyled>
              <ApproveButton review={review} setReviewsData={setReviewsData} />
              <Row gutter={[16, 16]}>
                <Col className="rating-and-create" span={24}>
                  <StarRating rate={review.rate} />
                  <span className="created-at">{moment(review.created_at).locale('ua').format('LLLL')}</span>
                </Col>
                <Col span={24} className="personal-info-wrapper">
                  <span className="title">{t('products.reviews_page.personal_info')}</span>
                  <div className="personal-info-container">
                    <div>
                      <h6>{t('products.reviews_page.author_name')}:</h6>
                      <span className="text">{review.author_name}</span>
                    </div>
                    <div>
                      <h6>{t('products.reviews_page.author_email')}:</h6>
                      {review.author_email ? <span>{review.author_email}</span> : <span style={{ color: '#d6ab00' }}>Немає інформації</span>}
                    </div>
                    <div>
                      <h6>{t('products.reviews_page.author_phone')}:</h6>
                      {review.author_phone ? <span>{review.author_phone}</span> : <span style={{ color: '#d6ab00' }}>Немає інформації</span>}
                    </div>
                  </div>
                </Col>
                <Col span={24} className="personal-info-comment">
                  <span className="title">{t('products.reviews_page.comment')}</span>
                  {review.content ? (
                    <div className="comment-text">{review.content}</div>
                  ) : (
                    <p className="not-comment">{t('products.reviews_page.not_comment_message')}</p>
                  )}
                </Col>
              </Row>
            </CommentsTableStyled>
          </Col>
        ))}
      </Row>

      <PaginationStyled
        total={total}
        showTotal={(total) => (
          <span className="total">
            {t('products.reviews_page.total')} {total}
          </span>
        )}
        current={page}
        pageSize={perPage}
        onChange={handlePageChange}
        showSizeChanger
        onShowSizeChange={(current, size) => {
          navigate(`?page=${current}`);
          setPerPage(size);
        }}
        pageSizeOptions={['6', '12', '18', '24']}
        itemRender={(page, type) => {
          const pageLink = `?page=${page}`;
          const pageText = type === 'next' ? '>' : type === 'prev' ? '<' : page;

          return <Link to={pageLink}>{pageText}</Link>;
        }}
      />
    </div>
  );
};

ReviewsPage.propTypes = {
  reviewsData: PropTypes.array,
  setReviewsData: PropTypes.func,
  total: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  setPerPage: PropTypes.func,
};

export default ReviewsPage;
