import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, message, Row } from 'antd';
import { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import { StringParam, useQueryParam } from 'use-query-params';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { CardToolbox, Main, PageSorting } from '../../../styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import 'react-sortable-tree/style.css';
import ApiClient from '@/helpers/apiClient/ApiClient';
import CategoriesTree from '@/components/Categories/CategoriesTree';
import Table from '@/widgets/Table/Table';
import appendPathNameToTree from '@/helpers/appendPathNameToTree';

const DISPLAY_TYPE_TABLE = 'table';
const DISPLAY_TYPE_GRID = 'grid';

const transformGetData = (nodes) => {
  return nodes.map(({ name, children, ...rest }) => {
    return {
      title: name,
      ...rest,
      children: children ? transformGetData(children) : [],
    };
  });
};

const transformSendData = (data) => {
  return data.map(({ title, id, children }) => {
    return {
      id,
      name: title,
      children: children ? transformSendData(children) : [],
    };
  });
};

const CategoryIndex = () => {
  const { t } = useTranslation();
  const [treeData, setTreeData] = useState([]);
  const [editNodeKey, setEditNodeKey] = useState(null);
  const [isAddedNode, setIsAddedNode] = useState(false);
  const [errors, setErrors] = useState({});
  const [newCategory, setNewCategory] = useState('');
  const [displayType] = useQueryParam('display', StringParam);

  const columns = React.useMemo(
    () => [
      {
        title: t('categories.fields.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('categories.fields.is_active'),
        dataIndex: 'is_active',
        key: 'is_active',
        render: (value) => <span className={classNames('status-text', value ? 'active' : 'deactivate')}>{value ? 'Так' : 'Ні'}</span>,
      },
      {
        title: t('categories.fields.count_product'),
        dataIndex: 'products_count',
        key: 'products_count',
      },
    ],
    [],
  );

  const handleSubmit = () => {
    setErrors({});
    const data = transformSendData(treeData);

    ApiClient.call('post', 'categories/tree', {}, { categories: data })
      .data(({ data }) => {
        setTreeData(transformGetData(data));
        message.success('Дані успішно збережені');
      })
      .validation((info, err) => {
        setErrors(err);
      });
  };

  useEffect(() => {
    ApiClient.call('get', 'categories/tree').data(({ data }) => {
      const transformedData = transformGetData(data);
      setTreeData(transformedData);
    });
  }, []);

  const addNode = (rowData) => {
    const { path } = rowData;

    setIsAddedNode(true);
    const newNode = {
      title: '',
      children: [],
      isEditing: true,
    };

    const updatedTree = addNodeUnderParent({
      treeData,
      newNode,
      expandParent: true,
      parentKey: path[path.length - 1],
      getNodeKey: ({ treeIndex }) => treeIndex,
    });

    setTreeData(updatedTree.treeData);

    setEditNodeKey([...path, updatedTree.treeIndex].join('-'));
  };

  const removeNode = (rowData) => {
    const { path } = rowData;

    const updatedTree = removeNodeAtPath({
      treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });

    setTreeData(updatedTree);
    setIsAddedNode(false);
  };

  const saveNodeTitle = (rowData, newTitle) => {
    if (newTitle.trim() === '') {
      message.error('Назва не може бути порожньою');
      return;
    }

    const { path } = rowData;

    const updatedTree = changeNodeAtPath({
      treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: { ...rowData.node, title: newTitle, isEditing: false },
    });

    setTreeData(updatedTree);
    setIsAddedNode(false);
  };

  const handleAddCategory = () => {
    if (!newCategory.trim()) {
      message.error('Назва категорії не може бути порожньою');
      return;
    }

    setTreeData((prevData) => [...prevData, { title: newCategory, children: [] }]);
    setNewCategory('');
  };

  const addedPathNameToTreeData = useMemo(() => appendPathNameToTree(treeData, 'categories'), [treeData]);

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={displayType === DISPLAY_TYPE_TABLE ? t('categories.list_page_header_table') : t('categories.list_page_header_grid')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1" onClick={handleSubmit}>
              Зберегти
            </Button>,
            <Button className="btn-add_new" size="default" type="primary" key="2">
              <Link to="/cms/categories/create">+ {t('categories.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <PageSorting>
              <div className="page-sort-bar">
                <div className="page-sort-group">
                  <div className="sort-group">
                    <div className="layout-style">
                      <Link to={`?display=${DISPLAY_TYPE_TABLE}`} className={classNames({ active: displayType === DISPLAY_TYPE_TABLE })}>
                        <FeatherIcon icon="list" size={16} />
                      </Link>
                      <Link to={`?display=${DISPLAY_TYPE_GRID}`} className={classNames({ active: displayType === DISPLAY_TYPE_GRID })}>
                        <FeatherIcon icon="grid" size={16} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </PageSorting>
          </Col>
        </Row>
        {displayType === DISPLAY_TYPE_TABLE ? (
          <Table columns={columns} route="cms/categories" resource="categories" permissionNamespace="cms.categories" />
        ) : (
          <CategoriesTree
            treeData={addedPathNameToTreeData}
            setTreeData={setTreeData}
            isAddedNode={isAddedNode}
            editNodeKey={editNodeKey}
            setEditNodeKey={setEditNodeKey}
            addNode={addNode}
            removeNode={removeNode}
            saveNodeTitle={saveNodeTitle}
            handleAddCategory={handleAddCategory}
            newCategory={newCategory}
            setNewCategory={setNewCategory}
            errors={errors}
          />
        )}
      </Main>
    </>
  );
};

export default withAuthLayout(CategoryIndex, false);
