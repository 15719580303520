import { Badge, Col, Radio, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import withAuthLayout from '@/layouts/withAuthLayout';
import { CardToolbox, Main, TopToolBox } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import { AutoComplete } from '@/components/autoComplete/autoComplete';
import Table from '@/widgets/Table/Table';
import useUrlParams from '@/hooks/useUrlParams';
import TextFilter from '@/widgets/Table/TextFilter';
import {
  ORDER_PAYMENT_STATUS_FAILED,
  ORDER_PAYMENT_STATUS_PAID,
  ORDER_PAYMENT_STATUS_PENDING,
  ORDER_STAGE_COMPLETED,
  ORDER_STAGE_DELIVERING,
  ORDER_STAGE_BOUQUET_READY,
  ORDER_STAGE_IN_QUE_FOR_PACKING,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_DONE,
  ORDER_STATUS_NEED_CONFIRMATION,
  ORDER_STATUS_PROCESSING,
  ORDER_DELIVERY_TYPE_SELF,
  ORDER_DELIVERY_TYPE_COURIER,
  ORDER_PAYMENT_TYPE_CASH,
  ORDER_PAYMENT_TYPE_ACQUIRING,
  ORDER_PAYMENT_STATUS_REVERSED,
} from '@/constants/orders';
import priceFormat from '@/helpers/priceFormat';
import { BadgeWraperStyle } from '@/container/ui-elements/ui-elements-styled';
import { Tag } from '@/components/tags/tags';
import { Button } from '@/components/buttons/buttons';
import DateRangeFilter from '@/widgets/Table/DateRangeFilter';
import AsyncSelectFilter from '@/widgets/Table/AsyncSelectFilter';
import TimeRangeFilter from '@/widgets/Table/TimeRangeFilter';
import CallButton from '@/components/Order/CallButton';
import ShortOrderInfoModal from '@/components/Order/OrderShortModal/ShortOrderInfoModal';
import { DD_MM_Y, DD_MM_Y_HH_mm } from '@/constants/momentFormat';

const STAGES = {
  [ORDER_STAGE_IN_QUE_FOR_PACKING]: 'yellow',
  [ORDER_STAGE_DELIVERING]: 'geekblue',
  [ORDER_STAGE_BOUQUET_READY]: 'cyan',
  [ORDER_STAGE_COMPLETED]: 'green',
};

const STATUSES = {
  [ORDER_STATUS_NEED_CONFIRMATION]: 'orange',
  [ORDER_STATUS_PROCESSING]: 'cyan',
  [ORDER_STATUS_DONE]: 'green',
  [ORDER_STATUS_CANCELLED]: 'red',
};

const PAYMENT_STATUSES = {
  [ORDER_PAYMENT_STATUS_PENDING]: 'geekblue',
  [ORDER_PAYMENT_STATUS_PAID]: 'lime',
  [ORDER_PAYMENT_STATUS_FAILED]: 'volcano',
  [ORDER_PAYMENT_STATUS_REVERSED]: 'blue',
};

const OrderIndex = () => {
  const { t } = useTranslation();
  const { filters, setFilterOnly } = useUrlParams();
  const [viewableId, setViewableId] = useState(null);

  const columns = React.useMemo(
    () => [
      {
        title: t('orders.number'),
        dataIndex: 'billing_number',
        key: 'billing_number',
        filterDropdown: (props) => <TextFilter {...props} />,
      },
      {
        title: t('orders.delivery_date'),
        dataIndex: 'delivery_date',
        key: 'delivery_date',
        render: (value) => <span>{moment(value).format(DD_MM_Y)}</span>,
        filterDropdown: (props) => <DateRangeFilter {...props} />,
        sorter: true,
      },
      {
        title: t('orders.delivery_time_range'),
        dataIndex: 'delivery_time_range',
        key: 'delivery_time_from',
        filterDropdown: (props) => <TimeRangeFilter {...props} />,
        sorter: true,
      },
      {
        title: t('orders.city_id'),
        key: 'city_id',
        render: (_, record) => <span>{record.store.city.name}</span>,
        filterDropdown: (props) => <AsyncSelectFilter {...props} searchUrl="cities/search" />,
      },
      {
        title: t('orders.store'),
        dataIndex: 'store',
        key: 'store_id',
        render: (value) => <span>{value.name}</span>,
        filterDropdown: (props) => <AsyncSelectFilter {...props} searchUrl="stores/search" />,
      },
      {
        title: t('orders.client_phone'),
        dataIndex: 'client_phone',
        key: 'client_id',
        render: (_, item) => <span>{item.client.phone_number}</span>,
        filterDropdown: (props) => <AsyncSelectFilter {...props} searchUrl="clients/search" />,
      },
      {
        title: t('orders.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value) => <span>{moment(value).format(DD_MM_Y_HH_mm)}</span>,
        filterDropdown: (props) => <DateRangeFilter {...props} />,
        sorter: true,
      },
      {
        title: t('orders.delivery_details'),
        dataIndex: 'delivery_details',
        key: 'delivery_details',
      },
      {
        title: t('orders.client_comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: t('orders.status'),
        dataIndex: 'status',
        key: 'status',
        render: (value) => <Tag color={STATUSES[value]}>{t(`orders.statuses.${value}`)}</Tag>,
        filters: [
          {
            text: t(`orders.statuses.${ORDER_STATUS_NEED_CONFIRMATION}`),
            value: ORDER_STATUS_NEED_CONFIRMATION,
          },
          {
            text: t(`orders.statuses.${ORDER_STATUS_PROCESSING}`),
            value: ORDER_STATUS_PROCESSING,
          },
          {
            text: t(`orders.statuses.${ORDER_STATUS_DONE}`),
            value: ORDER_STATUS_DONE,
          },
          {
            text: t(`orders.statuses.${ORDER_STATUS_CANCELLED}`),
            value: ORDER_STATUS_CANCELLED,
          },
        ],
      },
      {
        title: t('orders.payment'),
        dataIndex: 'payment_status',
        key: 'payment_status',
        render: (value) => (
          <span>
            <Tag color={PAYMENT_STATUSES[value]}>{t(`orders.payment_statuses.${value}`)}</Tag>
          </span>
        ),
        filters: [
          {
            text: t(`orders.payment_statuses.${ORDER_PAYMENT_STATUS_PENDING}`),
            value: ORDER_PAYMENT_STATUS_PENDING,
          },
          {
            text: t(`orders.payment_statuses.${ORDER_PAYMENT_STATUS_PAID}`),
            value: ORDER_PAYMENT_STATUS_PAID,
          },
          {
            text: t(`orders.payment_statuses.${ORDER_PAYMENT_STATUS_FAILED}`),
            value: ORDER_PAYMENT_STATUS_FAILED,
          },
          {
            text: t(`orders.payment_statuses.${ORDER_PAYMENT_STATUS_REVERSED}`),
            value: ORDER_PAYMENT_STATUS_REVERSED,
          },
        ],
      },
      {
        title: t('orders.payment_type'),
        dataIndex: 'payment_type',
        key: 'payment_type',
        render: (value) => <span>{t(`orders.payment_types.${value}`)}</span>,
        filters: [
          {
            text: t(`orders.payment_types.${ORDER_PAYMENT_TYPE_CASH}`),
            value: ORDER_PAYMENT_TYPE_CASH,
          },
          {
            text: t(`orders.payment_types.${ORDER_PAYMENT_TYPE_ACQUIRING}`),
            value: ORDER_PAYMENT_TYPE_ACQUIRING,
          },
        ],
      },
      {
        title: t('orders.delivery_type'),
        dataIndex: 'delivery_type',
        key: 'delivery_type',
        render: (value) => <span>{t(`orders.delivery_types.${value}`)}</span>,
        filters: [
          {
            text: t(`orders.delivery_types.${ORDER_DELIVERY_TYPE_COURIER}`),
            value: ORDER_DELIVERY_TYPE_COURIER,
          },
          {
            text: t(`orders.delivery_types.${ORDER_DELIVERY_TYPE_SELF}`),
            value: ORDER_DELIVERY_TYPE_SELF,
          },
        ],
      },
      {
        title: t('orders.stage'),
        dataIndex: 'stage',
        key: 'stage',
        render: (value) => {
          return (
            <BadgeWraperStyle>
              <Badge style={{ display: 'block ruby' }} color={STAGES[value]} text={t(`orders.stages.${value}`)} />
            </BadgeWraperStyle>
          );
        },
        filters: [
          {
            text: t(`orders.stages.${ORDER_STAGE_IN_QUE_FOR_PACKING}`),
            value: ORDER_STAGE_IN_QUE_FOR_PACKING,
          },
          {
            text: t(`orders.stages.${ORDER_STAGE_DELIVERING}`),
            value: ORDER_STAGE_DELIVERING,
          },
          {
            text: t(`orders.stages.${ORDER_STAGE_BOUQUET_READY}`),
            value: ORDER_STAGE_BOUQUET_READY,
          },
          {
            text: t(`orders.stages.${ORDER_STAGE_COMPLETED}`),
            value: ORDER_STAGE_COMPLETED,
          },
        ],
      },
      {
        title: t('orders.need_call'),
        dataIndex: 'need_call',
        key: 'need_call',
        render: (value) => <Tag color={value ? 'green' : 'red'}>{value ? t(`orders.need_call_statuses.call`) : t(`orders.need_call_statuses.not_call`)}</Tag>,
      },
      {
        title: t('orders.received_at'),
        dataIndex: 'received_at',
        key: 'received_at',
        render: (value) => <span>{value ? moment(value).format(DD_MM_Y_HH_mm) : '-'}</span>,
      },
      {
        title: t('orders.sum'),
        dataIndex: 'sum',
        key: 'sum',
        className: 'text-left',
        render: (value) => <span>{priceFormat(value)}</span>,
      },
      {
        title: t('orders.promo_code'),
        dataIndex: 'promo_code',
        key: 'promo_code_id',
        render: (value) => (value ? <Link to={`/crm/promo-codes/${value.id}`}>{value.code}</Link> : <span>-</span>),
        filterDropdown: (props) => <AsyncSelectFilter {...props} searchUrl="promo-codes/search" />,
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('orders.list_page_header')}
          // buttons={[
          //   <Button className="btn-add_new" size="default" type="primary" key="1">
          //     <Link to="/crm/orders/create">+ {t('orders.create_button')}</Link>
          //   </Button>,
          // ]}
        />
      </CardToolbox>

      <Main>
        {viewableId && <ShortOrderInfoModal id={viewableId} onClose={() => setViewableId(null)} />}
        <Row gutter={24}>
          <Col xs={24}>
            <TopToolBox>
              <Row gutter={0}>
                <Col xxl={8} lg={12} xs={24}>
                  <AutoComplete
                    placeholder={t('orders.search_to_number')}
                    searchUrl="orders/search"
                    onSelect={(value) => setFilterOnly('id', value)}
                    width="100%"
                    patterns
                  />
                </Col>
                <Col xxl={16} xs={24}>
                  <div className="product-list-action d-flex justify-content-end align-items-center">
                    <div className="product-list-action__tab">
                      <Radio.Group
                        onChange={(e) => setFilterOnly('status', e.target.value ? [e.target.value] : null)}
                        defaultValue={null}
                        value={filters?.status}
                      >
                        <Radio.Button value="">{t('orders.all')}</Radio.Button>
                        <Radio.Button value={ORDER_STATUS_NEED_CONFIRMATION}>{t(`orders.statuses.${ORDER_STATUS_NEED_CONFIRMATION}`)}</Radio.Button>
                        <Radio.Button value={ORDER_STATUS_PROCESSING}>{t(`orders.statuses.${ORDER_STATUS_PROCESSING}`)}</Radio.Button>
                        <Radio.Button value={ORDER_STATUS_DONE}>{t(`orders.statuses.${ORDER_STATUS_DONE}`)}</Radio.Button>
                        <Radio.Button value={ORDER_STATUS_CANCELLED}>{t(`orders.statuses.${ORDER_STATUS_CANCELLED}`)}</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </Col>
              </Row>
            </TopToolBox>
          </Col>
        </Row>

        <Table
          columns={columns}
          route="crm/orders"
          resource="orders"
          permissionNamespace="crm.orders"
          defaultSort={{ field: 'created_at', direction: 'descend' }}
          actionOptions={{ isDeletable: false, isEditable: false }}
          additionalActions={(row, setData) => {
            return (
              <>
                {row.need_call && (
                  <CallButton
                    clientData={{ id: row.id, name: row.client.name, phone: row.client.phone_number }}
                    onSuccess={() => {
                      setData((data) => {
                        const index = data.findIndex((el) => el.id === row.id);
                        const items = [...data];
                        items[index] = { ...items[index], need_call: false };

                        return items;
                      });
                    }}
                  />
                )}

                {/* <Button> */}
                {/*  <Link to={`/crm/orders/${row.id}`}> */}
                {/*    <FeatherIcon icon="edit" size={16} /> */}
                {/*  </Link> */}
                {/* </Button> */}

                <Button onClick={() => setViewableId(row.id)} style={{ cursor: 'pointer' }} role="button">
                  <FeatherIcon icon="eye" size={16} />
                </Button>
                <Button type="link" title={t('orders.status_order')}>
                  {/* eslint-disable-next-line */}
                  <a href={row.order_status_url} target="_blank" rel="noreferrer">
                    <FeatherIcon icon="navigation" size={16} />
                  </a>
                </Button>
              </>
            );
          }}
        />
      </Main>
    </>
  );
};

export default withAuthLayout(OrderIndex);
