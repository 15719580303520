import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'antd';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GeoClient from '@/helpers/apiClient/GeoClient';
import AsyncSelect from '@/widgets/AsyncSelect';
import ApiClient from '@/helpers/apiClient/ApiClient';

const LocalityModal = ({ value = {}, onChange, onClose, cityId, localityData }) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);
  const { city_id, ext_district_id, district_name, ext_city_id, city_name, ext_region_id, region_name } = localValue;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const saveDeliveryPoint = (method, url, data, cb) => {
    ApiClient.call(method, url, {}, data)
      .data(({ data }) => {
        cb(data);
        setErrors(null);
        onClose();
      })
      .catch(({ data }) => setErrors(data.errors));
  };

  const handleOnOk = () => {
    const data = {
      city_id: city_id || cityId,
      ext_district_id,
      district_name,
      ext_city_id,
      city_name,
      ext_region_id,
      region_name,
    };

    const method = value.id ? 'patch' : 'post';
    const url = value.id ? `delivery-points/${value.id}` : 'delivery-points';
    const cb = value.id
      ? (data) => {
          const updatedLocalityData = localityData.map((item) => (item.id === data.id ? { ...item, ...data } : item));
          onChange(updatedLocalityData);
        }
      : (data) => {
          onChange([...localityData, data]);
        };

    saveDeliveryPoint(method, url, data, cb);
  };

  return (
    <Modal open={!isEmpty(value)} onCancel={onClose} onOk={handleOnOk} title={value.id ? t('cities.fields.edited') : t('cities.fields.created')}>
      <Form.Item label={t('cities.fields.region')} validateStatus={get(errors, `ext_region_id`) ? 'error' : false} help={get(errors, `ext_region_id`)}>
        <AsyncSelect
          resource="regions"
          client={GeoClient}
          value={ext_region_id}
          onChange={(value, option) => {
            const regionName = option?.label || '';
            setLocalValue((prev) => ({ ...prev, ext_district_id: '', ext_city_id: '', ext_region_id: value, region_name: regionName }));
          }}
        />
      </Form.Item>
      <Form.Item label={t('cities.fields.district')} validateStatus={get(errors, `ext_district_id`) ? 'error' : false} help={get(errors, `ext_district_id`)}>
        <AsyncSelect
          disabled={!ext_region_id}
          value={ext_district_id}
          params={{ region_id: ext_region_id }}
          resource="districts"
          client={GeoClient}
          onChange={(val, option) => {
            const districtName = option?.label || '';
            setLocalValue((prev) => ({ ...prev, ext_district_id: val, district_name: districtName }));
          }}
        />
      </Form.Item>
      <Form.Item label={t('cities.fields.city')} validateStatus={get(errors, `ext_city_id`) ? 'error' : false} help={get(errors, `ext_city_id`)}>
        <AsyncSelect
          disabled={!ext_district_id}
          value={ext_city_id}
          params={{ region_id: ext_region_id, district_id: ext_district_id }}
          resource="cities"
          client={GeoClient}
          onChange={(val, option) => {
            const cityName = option?.label || '';
            setLocalValue((prev) => ({ ...prev, ext_city_id: val, city_name: cityName }));
          }}
        />
      </Form.Item>
    </Modal>
  );
};

LocalityModal.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  localityData: PropTypes.array,
};

export default LocalityModal;
